<template>
  <div class="home">
    <!-- <div class="banner">
      <img class="banner__img" src="../assets/image/bannner@3x.jpg" alt="">
      <a class="banner__link hide-in-mobile" @click="getProductList">放入詢問清單</a>
    </div> -->
    <Slide :promote="promoteList"></Slide>
    <div class="flex f-row f-wrap squares">
      <div class="mob-w-100 w-50 squares__item flex f-row jcc aic" style="padding: 0">
        <img class="mob-w-100 h-100" src="@/assets/image/about.png">
      </div>
      <div class="mob-w-100 w-50 squares__item flex f-col jcc aic bg-highlight">
        <h4 class="">WEWHISKYBARN</h4>
        <h3>關於我們</h3>
        <p>
        酒，在世界各地的文化中，不同的人種中，是一種共同的語言。調劑著每個人的生活。<br>
        您可曾聽過， ”對酒當歌，人生幾何”，“古來聖賢皆寂寞，惟有飲者留其名”，”勸君更盡一杯酒…”，”酒意詩情誰與共…” <br> 
        從酒出現的場合，可以了解酒在人生中的不同環境和場合有其獨特的義意。<br>
        威酒穀倉是一群對酒有著熱情的人催生下的品牌。 基於對客戶的深入了解。誠信，品質和服務是我們的信條。<br>
        我們致力於提供客戶多樣且不同的威士忌，讓每個客戶體驗到不同的驚喜。 誠摯的邀請您一起享受威士忌的樂趣…</p>
      </div>
      <div class="mob-w-100 w-50 squares__item flex f-col jcc aic bg-highlight">
        <h4 class="">SIGN UP RIGHT NOW</h4>
        <h3>立即註冊</h3>
        <div @click="goToAccountPage" class="btn btn-square">立即註冊</div>
      </div>
      <div class="mob-w-100 w-50 squares__item flex jcc aic">
        <div class="map">
          <img class="map__bg w-100" src="@/assets/image/map/glass.png" alt="">
          <img class="map__bottom w-100" src="@/assets/image/map/map--plain.png">
        </div>
      </div>
    </div>

    <div class="flex f-col jcc aic contact">
      <h4 class="">CONTACT US</h4>
      <h3>聯絡我們</h3>
      <div class="flex f-row jcc aic">
        <a class="social-icon" href="https://www.facebook.com/we.whisky.barn/" target="_blank">
          <img src="@/assets/image/icon/facebook-b.png" alt="">
        </a>
        <a class="social-icon" href="https://www.instagram.com/we.whisky.barn/" target="_blank">
          <img src="@/assets/image/icon/instagram-b.png" alt="">
        </a>
        <a class="social-icon" href="tel:+886-925918530">
          <img src="@/assets/image/icon/phone-b.png" alt="">
        </a>
        <div class="line-it-button" data-lang="zh_Hant" data-type="friend" data-env="REAL"   data-lineId="@989idazb" style="display: none;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Slide from '@/components/Slide.vue'
export default {
  name: 'Home',
  components: {
    Slide,
  },
  methods: {
    async getPromoteList() {
      // const params = { 
      //   currentPage: this.currentPage, 
      //   perPage: 20, 
      //   cate_id: this.cate_id,
      //   brand_id: this.brand_id,  
      //   area_id: this.area_id,
      //   category_id: this.category_id
      // };
      await this.$store.dispatch('promote/getPromoteList', {});
      // document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
    },
    goToAccountPage() {
      const token = localStorage.getItem('ww_token');
      if(token) {
        this.$router.push(`/account/${token}`);
      } else {
        this.$router.push('/login');
      }
    },
  },
  computed: {
    promoteList() {
      return this.$store.getters['promote/promoteList'];
    }
  },
  mounted() {
    this.getPromoteList();
  }
}
</script>
