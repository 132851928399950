<template>
  <div class="">
    <!-- web -->
    <div 
      ref="headerH" 
      class="header hide-in-mobile flex f-row jcsb aie"
      :class="{'header-shrink aic': headerShrink}">
      <div class="header__logo">
        <router-link to="/">
          <img src="@/assets/image/logo.png">
        </router-link>
      </div>
      <div class="flex f-row jcs aic">
        <div class="nav__item">誠摯的邀請您一起享受威士忌的樂趣</div>
        <!-- <router-link to="/about">
          <div class="nav__item mr-2">關於我們</div>
        </router-link>
        <router-link to="/product-list">
          <div
            class="nav__item product mr-2"
            @mouseover="isOpenMenu = true"
            @mouseleave="isOpenMenu = false">
            <span>產品介紹</span>
            <div class="nav__drop"
              @mouseover="isOpenMenu = true"
              @mouseleave="isOpenMenu = false"
              v-if="isOpenMenu">
              <div class="flex f-row jcs ais">
                <div class="nav__drop__cate">
                  <div class="nav__drop__cate__title">Shop by 產區</div>
                  <div class="nav__drop__cate__item"
                    v-for="a in areaList"
                    :key="a.area_id"
                    @mouseover="tempAreaId = a.area_id"
                    @click.prevent="getProductList({ area_id: a.area_id })">
                    {{ a.area_name[1] }}
                  </div>
                </div>
                <div class="nav__drop__cate">
                  <div class="nav__drop__cate__title">Shop by 品牌</div>
                  <div class="nav__drop__cate__item"
                    v-for="b in filterBrand"
                    :key="b.brand_id"
                    @click.prevent="getProductList({ brand_id: b.brand_id })">
                    {{ b.brand_name[1] }}
                  </div>
                </div>
                <div class="nav__drop__cate">
                  <div class="nav__drop__cate__title">Shop by 類別</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 0 })"
                  >
                    Single Malt Whisky</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 1 })"
                  >
                    Blended Whisky</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 2 })"
                  >
                    Grain Whisky</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 3 })"
                  >
                    Whisky Liqueur</div>
                </div>
                <img src="@/assets/image/product/johnny.png" class="nav__drop__img">
              </div>
            </div>
          </div>
        </router-link>
        <router-link to="/contact-us">
          <div class="nav__item mr-2">聯絡我們</div>
        </router-link> -->
      </div>
      <div class="flex f-row jcsb ais">
          <!-- <img src="@/assets/image/icon/search-w.png" class="icon"> -->
          <!-- <img class="icon" src="@/assets/image/icon/heart.png" alt=""> -->
          <img v-if="Object.values(cus_info).length > 0" @click="goToAccountPage" class="icon avatar" :src="cus_info.cus_avatar" alt="">
          <img v-else @click="goToAccountPage" class="icon" src="@/assets/image/icon/user-w.png" alt="">
          <!-- <img @click="goToInquirePage" class="icon" src="@/assets/image/icon/shopping-bag.png" alt=""> -->
      </div>
    </div>
    <!-- <div class="header hide-in-mobile">
      <div class="flex f-row jcsb ais">
        <div class="header__search">
          <img src="@/assets/image/icon/search.png" class="icon">
        </div>
        <router-link to="/">
          <img src="@/assets/image/logo.png" class="header__logo">
        </router-link>
        
        <div class="header__user flex f-row jcs aic">
          <img class="icon" src="@/assets/image/icon/heart.png" alt="">
          <img class="icon" src="@/assets/image/icon/user.png" alt="">
          <img @click="goToInquirePage" class="icon" src="@/assets/image/icon/shopping-bag.png" alt="">
        </div>
      </div>
      <div class="flex f-row jcsb aie nav">
        <router-link to="/about">
          <div class="nav__item">關於我們</div>
        </router-link>
        <router-link to="/product-list">
          <div
            class="nav__item product"
            @mouseover="isOpenMenu = true"
            @mouseleave="isOpenMenu = false">
            <span>產品介紹</span>
            <div class="nav__drop"
              @mouseover="isOpenMenu = true"
              @mouseleave="isOpenMenu = false"
              v-if="isOpenMenu">
              <div class="flex f-row jcs ais">
                <div class="nav__drop__cate">
                  <div class="nav__drop__cate__title">Shop by 產區</div>
                  <div class="nav__drop__cate__item"
                    v-for="a in areaList"
                    :key="a.area_id"
                    @mouseover="tempAreaId = a.area_id"
                    @click.prevent="getProductList({ area_id: a.area_id })">
                    {{ a.area_name[1] }}
                  </div>
                </div>
                <div class="nav__drop__cate">
                  <div class="nav__drop__cate__title">Shop by 品牌</div>
                  <div class="nav__drop__cate__item"
                    v-for="b in filterBrand"
                    :key="b.brand_id"
                    @click.prevent="getProductList({ brand_id: b.brand_id })">
                    {{ b.brand_name[1] }}
                  </div>
                </div>
                <div class="nav__drop__cate">
                  <div class="nav__drop__cate__title">Shop by 類別</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 0 })"
                  >
                    Single Malt Whisky</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 1 })"
                  >
                    Blended Whisky</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 2 })"
                  >
                    Grain Whisky</div>
                  <div
                    class="nav__drop__cate__item"
                    @click.prevent="getProductList({ category_id: 3 })"
                  >
                    Whisky Liqueur</div>
                </div>
                <img src="@/assets/image/product/johnny.png" class="nav__drop__img">
              </div>
            </div>
          </div>
        </router-link>
        <router-link to="/inquiry">
          <div class="nav__item">酒品詢問</div>
        </router-link>
        <router-link to="/contact-us">
          <div class="nav__item">聯絡我們</div>
        </router-link>
        <router-link to="/blog">
          <div class="nav__item">Blog</div>
        </router-link>
      </div>
    </div> -->
    <!-- end web -->
    <!-- mob -->
    <div class="show-in-mobile flex f-col jcs aic">
      <div class="header flex f-row jcsb aic w-100">
        <router-link to="/">
          <img src="@/assets/image/logo.png" class="header__logo">
        </router-link>
        <div class="flex f-row jse aic">
          <!-- <img src="@/assets/image/icon/search-b.png" class="header__search icon"> -->
          <div :class="{'header__hamberger': true, 'active': isMobMenuOpen}" @click="onMobMenuOpen">
            <span class="header__hamberger__1"></span>
            <span class="header__hamberger__2"></span>
            <span class="header__hamberger__3"></span>
          </div>
        </div>
      </div>
      <div class="flex f-col jcs aic mob-menu" :class="{'active': isMobMenuOpen}">
        <div class="header__hamberger mt-3" :class="{'active': isMobMenuOpen}" @click="onMobMenuOpen">
          <span class="header__hamberger__1"></span>
          <span class="header__hamberger__2"></span>
          <span class="header__hamberger__3"></span>
        </div>
        <div class="mt-3">
          <router-link to="/about">
            <div class="nav__item">關於我們</div>
          </router-link>
          <router-link to="/product-list">
            <div class="nav__item">產品介紹</div>
          </router-link>
          <router-link to="/contact-us">
            <div class="nav__item">聯絡我們</div>
          </router-link>
          <div @click="goToAccountPage" class="nav__item">會員資訊</div>
        </div>
      </div>
    </div>
    <!-- end mob -->
  </div>
</template>

<script>
// import $ from 'jquery';
export default {
  name: 'Header',
  data(){
    return{
      isActive: false,
      isOpenMenu: false,
      tempAreaId: 1,
      windowTop: 0,
      headerShrink: false
    }
  },
  props: {
    areaList: Array,
    brandList: Array
  },
  watch: {
    $route (){
      this.$store.dispatch('setIsMobMenuOpen', false, { root: true });
    }
  },
  methods: {
    onScroll(e) {
      // console.log(e.target.documentElement.scrollTop);
      this.windowTop = e.target.documentElement.scrollTop;
      if(this.windowTop >= (this.$refs.headerH.clientHeight / 2)) {
        this.headerShrink = true;
      } else {
        this.headerShrink = false;
      }
      // this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
    },
    onMobMenuOpen() {
      let open = !this.isMobMenuOpen;
      this.$store.dispatch('setIsMobMenuOpen', open, { root: true });
    },
    goToAccountPage() {
      const token = localStorage.getItem('ww_token');
      if(token) {
        this.$router.push(`/account/${token}`);
      } else {
        this.$router.push('/login');
      }
    },
    async getProductList(param) {
      this.isOpenMenu = false;
      this.$router.push({ path: '/product-list', query: param });
      // await this.$store.dispatch('product/getProductList', param);
      // const current = this.$router.history.current.path;
      // if (current !== '/product-list') {
      //   this.$router.push(`/product-list`);
      // }
      document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
    }
  },
  computed: {
    isMobMenuOpen() {
      return this.$store.getters['isMobMenuOpen'];
    },
    filterBrand() {
      let filterBrand = [];
      if(this.brandList) {
        filterBrand = this.brandList.filter(item => {
          return item.area_id == this.tempAreaId;
        });
      }
      return filterBrand;
    },
    token() {
      const token = localStorage.getItem('ww_token') || '';
      if(token !== '') {
        return token;
      } else {
        return null;
      }
    },
    cus_info() {
      const cus_info = localStorage.getItem('ww_info') || '';
      if(cus_info !== '') {
        return JSON.parse(cus_info);
      } else {
        return {};
      }    
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
}
// $(document).ready(function(){
//   $("body").click(function(event){
//       var $trigger = $(".nav__item.product");
//       if($trigger !== event.target && !$trigger.has(event.target).length){
//         $(".nav__item.product").removeClass("active");
//       }  
//   })
//   $('.nav__item.product').hover(function(){
//     $('.nav__item.product').addClass('active');
//   })
// });
</script>