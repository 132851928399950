<template>
    <div :class="{'loading': true, 'show': isLoading}">
      <img src="@/assets/image/logo.png">
    </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style lang="scss">
.loading{
  z-index: 1001;
  position: fixed;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  display: none;
  &.show{
    display: flex;
    justify-content: center;
    align-items: center;
    animation: loading 2s;
  }
  img{
    width: 100px;
  }
}
@keyframes loading {
  0% {
    opacity: 1;
  }
  25%{
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75%{
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>