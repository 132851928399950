<template>
    <div class="footer">
        <!-- social media -->
        <!-- <div class="flex f-row jcc aic">
        </div> -->
        <div class="flex f-row jcc aic">
          <span>禁止酒駕</span>
          <img src="@/assets/image/icon/stop.png" alt="">
          <span>未滿18歲請勿飲酒</span>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Footer',
  props: {
  }
}
</script>