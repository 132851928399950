<template>
  <div class="main">
    <div class="banner">
      <img class="banner__img" src="@/assets/image/bannner.png" alt="">
    </div>
    <div class="flex f-row jcs ais px pt mob-flex-column" v-if="isComment">
      <div class="border__right__dash flex f-col jcs aic mob-w-100 mob-border-none mob-p-0" style="width: 50%">
        <img :src="product.data.prod_img[0]" alt="" class="product__info__img mr-3">
        <div class="product__info__title__md">{{ product.data.prod_name[1] }}</div>
        <div class="btn">再次購買</div>
      </div>
      <div class="flex f-row jcc ais w-100">
        <div class="flex f-col jcs aic comment__card">
          <div class="comment__card__title">Create your own tasting note：</div>
          <div class="dash__hr"></div>
          <div class="flex f-row jcs aic mb-3">
            <img src="@/assets/image/icon/star2.png" class="product__info__star">
            <img src="@/assets/image/icon/star2.png" class="product__info__star">
            <img src="@/assets/image/icon/star2.png" class="product__info__star">
            <img src="@/assets/image/icon/star2.png" class="product__info__star">
            <img src="@/assets/image/icon/star3.png" class="product__info__star">
          </div>
          <div class="comment__card__flavor">
            <div class="comment__card__flavor__label">Nose</div>
            <img
              src="@/assets/image/icon/flavor/nose.png"
              class="comment__card__flavor__logo">
            <div class="comment__card__flavor__items">
              <img src="@/assets/image/icon/flavor/apple.png" alt="">
              <img src="@/assets/image/icon/flavor/grape.png" alt="">
              <img src="@/assets/image/icon/flavor/peach.png" alt="">
              <img src="@/assets/image/icon/flavor/plum.png" alt="">
            </div>
          </div>
          <div class="comment__card__flavor">
            <div class="comment__card__flavor__label">Palate</div>
            <img
              src="@/assets/image/icon/flavor/palette.png"
              class="comment__card__flavor__logo">
            <div class="comment__card__flavor__items">
              <img src="@/assets/image/icon/flavor/apple.png" alt="">
              <img src="@/assets/image/icon/flavor/grape.png" alt="">
              <img src="@/assets/image/icon/flavor/peach.png" alt="">
              <img src="@/assets/image/icon/flavor/plum.png" alt="">
            </div>
          </div>
          <div class="comment__card__flavor">
            <div class="comment__card__flavor__label">Finishing</div>
            <img
              src="@/assets/image/icon/flavor/finishing.png"
              class="comment__card__flavor__logo">
            <div class="comment__card__flavor__items">
              <img src="@/assets/image/icon/flavor/apple.png" alt="">
              <img src="@/assets/image/icon/flavor/grape.png" alt="">
              <img src="@/assets/image/icon/flavor/peach.png" alt="">
              <img src="@/assets/image/icon/flavor/plum.png" alt="">
            </div>
          </div>
          <div class="comment__flavors mb-3">
            <div class="flex f-row jcsb aic mb-1">
              <img src="@/assets/image/icon/flavor/apple.png">
              <img src="@/assets/image/icon/flavor/grape.png" alt="">
              <img src="@/assets/image/icon/flavor/peach.png" alt="">
              <img src="@/assets/image/icon/flavor/plum.png" alt="">
              <img src="@/assets/image/icon/flavor/watermelon.png" alt="">
              <img src="@/assets/image/icon/flavor/strawberry.png" alt="">
            </div>
            <div class="flex f-row jcsb aic mb-1">
              <img src="@/assets/image/icon/flavor/apple.png">
              <img src="@/assets/image/icon/flavor/grape.png" alt="">
              <img src="@/assets/image/icon/flavor/peach.png" alt="">
              <img src="@/assets/image/icon/flavor/plum.png" alt="">
              <img src="@/assets/image/icon/flavor/watermelon.png" alt="">
              <img src="@/assets/image/icon/flavor/strawberry.png" alt="">
            </div>
            <div class="flex f-row jcsb aic">
              <img src="@/assets/image/icon/flavor/apple.png">
              <img src="@/assets/image/icon/flavor/grape.png" alt="">
              <img src="@/assets/image/icon/flavor/peach.png" alt="">
              <img src="@/assets/image/icon/flavor/plum.png" alt="">
              <img src="@/assets/image/icon/flavor/watermelon.png" alt="">
              <img src="@/assets/image/icon/flavor/strawberry.png" alt="">
            </div>
          </div>
          <div class="comment__card__comment mb-1">
            <div class="flex f-row jcsb aic">
              <div class="comment__card__comment__label">Comments</div>
              <div class="comment__card__comment__label">0/250</div>
            </div>
            <textarea
              cols="10"></textarea>
          </div>
          <div class="flex f-row jcsb aic w-100 mob-flex-row-reverse mob-np" style="padding-left: 40px;padding-right: 40px;">
            <div class="flex hide-in-mobile" style="width: 115px"></div>
            <div class="flex f-col jcc aic mob-flex-row-reverse" style="width: 115px">
              <div class="btn">Save</div>
              <div class="btn-default mob-np mob-mr-1">Cancel</div>
            </div>
            <div class="share flex f-row jcs aic mob-flex-column" style="width: 115px">
              <span class="t-sm">分享</span>
              <img src="@/assets/image/icon/like.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>  
    <div class="info" v-if="!isComment">
      <div class="flex f-row jcs ais px pt mb-3 mob-flex-column">
        <div class="flex f-row jcs product__info mob-flex-column mob-w-100">
          <div class="flex f-col jcs ais mr-3 mob-w-100" style="width: 60%">
            <div class="flex jcc aic w-100">
              <img
              :src="product.data.prod_img[0]"
              alt=""
              class="product__info__img mr-3 show-in-mobile">
            </div>
            <div class="product__info__title">{{ product.data.prod_name[1] }}</div>
            <div class="product__info__small">{{ product.data.prod_name[0] }}</div>
            <div class="flex f-row jcs aic mb-3">
              <img src="@/assets/image/icon/star2.png" class="product__info__star">
              <img src="@/assets/image/icon/star2.png" class="product__info__star">
              <img src="@/assets/image/icon/star2.png" class="product__info__star">
              <img src="@/assets/image/icon/star2.png" class="product__info__star">
              <img src="@/assets/image/icon/star3.png" class="product__info__star">
              <div class="product__info__reviews">(34 Reviews)</div>
            </div>
            <div class="product__info__desc">
              {{ product.data.prod_desc }}
            </div>
          </div>
          <img
            :src="product.data.prod_img[0]"
            alt=""
            class="product__info__img mr-3 hide-in-mobile"
            style="width: 40%">
        </div>
        <div class="product__stock__card flex f-col jcsb aic">
          <div class="flex f-row jcc aic">
            <div class="product__stock">
              <img class="product__stock__bottle" src="@/assets/image/icon/bottle.png" alt="">
              <span class="product__stock__num">{{ product.data.prod_stock }}</span>
              <span class="product__stock__left">Left</span>
            </div>
            <span class="product__stock__status">IN Stock</span>
          </div>
          <div class="flex f-col jcs ais">
            <div class="product__stock__text">建議售價：<span class="product__stock__price">NT{{ product.data.prod_suggest_price | numberWithCommas }}</span></div>
            <!-- <div class="product__stock__text">放入詢問箱：<img class="icon" src="@/assets/image/icon/shopping-bag-black.png" alt=""></div> -->
            <div @click="createWishlist" class="product__stock__text"><img class="icon" src="@/assets/image/icon/heart-black.png" alt="">Add to wishlist</div>
          </div>
        </div>
      </div>
      <!-- <div class="flex f-col jcs ais px pt mb-3">
        <div class="section__title mb-2">風味表</div>
        <div class="tabs flex f-row jcs ais w-100">
          <div class="tabs__item">Nose</div>
          <div class="tabs__item">Palate</div>
          <div class="tabs__item">Finising</div>
        </div>
        <div class="flex f-row jcs ais w-100">
          <div class="f-1 flex jcc aic">
            <div class="chart"></div>
          </div>
          <div class="f-1 flex jcc aic">
            <div class="chart"></div>
          </div>
          <div class="f-1 flex jcc aic">
            <div class="chart"></div>
          </div>
        </div>
      </div> -->
      <!-- <div class="flex f-col jcs ais px pt">
        <div class="flex f-col jcc aic w-100">
          <div class="btn btn-comment mb-3" @click="isComment = true">加入評論</div>
        </div>
        <div v-for="r in review.data" :key="`review-${r.prod_id}-${r.create_at}`" class="comment w-100 mob-flex-column jcsb">
          <div class="flex f-row jcs aic">
            <div class="comment__user flex f-col jcs ais">
              <div class="flex f-row jcs aic mb-3">
                <img class="comment__user__avatar" :src="r.cus_avatar">
                <div class="comment__user__name">{{ r.cus_fname }} {{ r.cus_lname }}</div>
              </div>
              <div class="flex f-row jcs aic mb-1">
                <div class="comment__user__label">等級：</div>
                <div class="comment__user__value">博士</div>
              </div>
              <div class="flex f-row jcs aic mb-1">
                <div class="comment__user__label">發表數：</div>
                <div class="comment__user__value">34</div>
              </div>
              <div class="flex f-row jcs aic">
                <div class="comment__user__label">回應數：</div>
                <div class="comment__user__value">34</div>
              </div>
            </div>
            <div class="comment__content flex f-col jcs ais">
              <div class="flex f-row jcs aic mb-3 mob-mt-3">
                <div class="comment__content__star" v-for="star in 5" :key="`star-${r.review_id}-${star}`" :id="`star-${r.review_id}-${star}`">
                  <img v-if="star <= r.review_stars" src="@/assets/image/icon/star2.png">
                  <img v-else src="@/assets/image/icon/star3.png">
                </div>
              </div>
              <div class="flex f-row jcs aic mb-2">
                <div class="comment__content__label">Nose：</div>
                <img v-for="n in r.review_nose.split(',')" :key="`nose-${n}`" class="comment__content__icon" :src="getImgUrl(n)">
              </div>
              <div class="flex f-row jcs aic mb-2">
                <div class="comment__content__label">Palate：</div>
                <img v-for="p in r.review_palate.split(',')" :key="`palate-${p}`" class="comment__content__icon" :src="getImgUrl(p)">
              </div>
              <div class="flex f-row jcs aic mb-3">
                <div class="comment__content__label">Finising：</div>
                <img v-for="f in r.review_finishing.split(',')" :key="`finishing-${f}`" class="comment__content__icon" :src="getImgUrl(f)">
              </div>
              <div class="flex f-row jcs ais">
                <div class="comment__content__label">Comments：</div>
                <div class="comment__content__text">
                  {{ r.review_comment }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex f-col jcs aic mob-flex-row mob-jcsb mob-w-100 mob-mt-3">
            <div class="flex f-row jcs aic">
              <div class="comment__like__num">34</div>
              <img class="comment__like__icon" src="@/assets/image/icon/like.png">
            </div>
            <div class="btn btn-edit">編輯</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
  components: {
  },
  data() {
    return {
      isComment: false,
    }
  },
  props: {
    prod_id: {
      type: String,
      default: null,
    },
  },
  methods: {
    randomReview() {
      let num = Math.floor(Math.random()*50)+1;
      return num;
    },  
    getImgUrl(pic) {
      return require(`@/assets/image/icon/flavor/${pic}.png`);
    },
    async getProductById() {
      await this.$store.dispatch('product/getProductById', this.prod_id);
    },
    // async getReview() {
    //   await this.$store.dispatch('review/getReview', { prod_id: this.prod_id });
    // },
    async createWishlist() {
      const token = localStorage.getItem('ww_token');
      try {
        if(!token) {
          const error = new Error('請先登入！');
          error.statusCode = 401;
          throw error;
        }
        await this.$store.dispatch('wishlist/createWishlist', { token, prod_id: this.prod_id});
        if(confirm('願望清單添加成功!是否前往願望清單頁面？')){
          this.$router.push(`/account/${token}`);
        }
      } catch(error) {
        const { statusCode, message } = error;
        if(statusCode == 400) {
          if(confirm('添加失敗，願望清單內已有該酒品，是否前往願望清單頁面？')){
            this.$router.push(`/account/${token}`);
          }
        } else if(statusCode == 401) {
          if(confirm('請先登入帳號再行加入，是否前往登入頁面？')){
            this.$router.push('/login');
          }
        } else {
          alert(message);
        }
      }
    }
  },
  filters: {
    numberWithCommas(price) {
      if (price !== null && price !== undefined) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return price;
      }
    },
  },
  computed: {
    product() {
      return this.$store.getters['product/product'];
    },
    // review() {
    //   return this.$store.getters['review/review'];
    // },
  },
  async created() {
    await this.getProductById();
    // await this.getReview();
  }
}
</script>
