import request from '@/utils/http';
// import requestFile from '@/utils/http-upload';

/** Get the review
 *
 * @param {Object} payload - request body
 */
export function apiGetReview(payload) {
  return request({
    url: `/review/${payload.prod_id}`,
    method: 'get',
    headers: { Authorization: payload.token },
  });
}

/** Create the review
 *
 * @param {Object} payload - request body
 */
export function apiCreateReview(payload) {
  const {
    token,
    prod_id,
    review_stars,
    review_nose,
    review_palate,
    review_finishing,
    review_comment,
  } = payload;
  return request({
    url: `/review`,
    method: 'post',
    data: {
      prod_id,
      review_stars,
      review_nose,
      review_palate,
      review_finishing,
      review_comment,
    },
    headers: { Authorization: token },
  });
}

/** Like the review
 *
 * @param {Object} payload - request body
 */
export function apiLikeReview(payload) {
  return request({
    url: `/review/like/${payload.review_id}`,
    method: 'get',
    headers: { Authorization: payload.token },
  });
}

/** Share the review
 *
 * @param {Object} payload - request body
 */
export function apiShareReview(payload) {
  return request({
    url: `/review/share/${payload.review_id}`,
    method: 'get',
    headers: { Authorization: payload.token },
  });
}

/** Delete the review
 *
 * @param {Object} payload - request body
 */
export function apiDeleteReview(payload) {
  return request({
    url: `/review/${payload.review_id}`,
    method: 'delete',
    headers: { Authorization: payload.token },
  });
}
