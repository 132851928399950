import {
  apiGetReview,
  apiDeleteReview,
  apiCreateReview,
  apiLikeReview,
  apiShareReview,
} from '@/api/review';
// import checkIsAuthError from '@/utils/check-is-auth-error';

export default {
  namespaced: true,
  state: {
    review: {},
  },
  getters: {
    review: (state) => state.review,
  },
  mutations: {
    SET_REVIEW(state, payload) {
      state.review = payload;
    },
  },
  actions: {
    getReview(state, payload) {
      return new Promise((resolve, reject) => {
        apiGetReview(payload)
          .then((res) => {
            state.commit('SET_REVIEW', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    createReview(state, payload) {
      return new Promise((resolve, reject) => {
        apiCreateReview(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    likeReview(state, payload) {
      return new Promise((resolve, reject) => {
        apiLikeReview(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    shareReview(state, payload) {
      return new Promise((resolve, reject) => {
        apiShareReview(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    deleteReview(state, payload) {
      return new Promise((resolve, reject) => {
        apiDeleteReview(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
  },
};
