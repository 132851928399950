import {
  apiGetWishlist,
  apiDeleteWishlist,
  apiCreateWishlist,
} from '@/api/wishlist';
// import checkIsAuthError from '@/utils/check-is-auth-error';

export default {
  namespaced: true,
  state: {
    wishlist: {},
  },
  getters: {
    wishlist: (state) => state.wishlist,
  },
  mutations: {
    SET_WISHLIST(state, payload) {
      state.wishlist = payload;
    },
  },
  actions: {
    getWishlist(state, payload) {
      return new Promise((resolve, reject) => {
        apiGetWishlist(payload)
          .then((res) => {
            state.commit('SET_WISHLIST', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    createWishlist(state, payload) {
      return new Promise((resolve, reject) => {
        apiCreateWishlist(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            const { status, data } = err.response;
            const error = new Error(data.message);
            error.statusCode = status;
            reject(error);
          });
      });
    },
    deleteWishlist(state, payload) {
      return new Promise((resolve, reject) => {
        apiDeleteWishlist(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
  },
};
