<template>
  <div class="main">
    <div class="banner">
      <img class="banner__img" src="@/assets/image/bannner.png" alt="">
    </div>
    <div class="flex f-col jcs aic sec px pt">
      <div class="" style="width: 1000px;max-width:100%">
        <div class="sec__title" id="content">註冊/登入</div>
        <div class="flex jcs aic f-row">
          <!-- <a href="http://localhost:8081/api/auth/google-login" class="btn btn-square btn-google btn-social">
            <img src="@/assets/image/icon/google.png" alt="">
            使用Google登入
          </a>
          <span style="color: #fff;margin-left: 10px;margin-right: 10px;">或</span> -->
          <a href="http://localhost:8081/api/auth/facebook-login" class="btn btn-square btn-facebook btn-social">
            <img src="@/assets/image/icon/facebook.png" alt="">
            使用Facebook登入
          </a>
          <!-- <a href="https://wewhiskybarn.com/api/auth/facebook-login" class="btn btn-square btn-facebook btn-social">
            <img src="@/assets/image/icon/facebook.png" alt="">
            使用Facebook登入
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
  },
  data(){
    return{
      tempContactUs: {
        contactus_name: '',
        contactus_phone: '',
        contactus_email: '',
        contactus_content: '',
        captcha_key: '',
        captcha_content: ''
      },
      formError: {
        contactus_name: false,
        contactus_phone: false,
        contactus_email: false,
        contactus_content: false,
        captcha_content: false
      }
    }
  },
  methods:{
    async googleLogin() {
      await this.$store.dispatch('auth/googleLogin');
    },
    goToProductPage() {
      console.log('goToProductPage');
    },
    deleteItem() {
      console.log('deleteItem');
    },
    async getCaptcha() {
      await this.$store.dispatch('auth/getCaptcha');
      this.tempContactUs.captcha_key = this.captcha.token;
    },
    changeCaptcha() {
      this.getCaptcha();
    },
    checkForm() {
      const emailRule = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      this.formError = {
        contactus_name: false,
        contactus_phone: false,
        contactus_email: false,
        contactus_content: false,
        captcha_content: false
      };
      if (!this.tempContactUs.contactus_name) { this.formError.contactus_name = true }
      if (!this.tempContactUs.contactus_phone) { this.formError.contactus_phone = true }
      if (!this.tempContactUs.contactus_email || !this.tempContactUs.contactus_email.match(emailRule)) { 
        this.formError.contactus_email = true;
      }
      if (!this.tempContactUs.captcha_content) { this.formError.captcha_content = true }
    },
    createContactUs() {
      this.checkForm();
      let errors = Object.values(this.formError);
      errors = errors.filter(item => {
        return item == true;
      })
      if(errors.length < 1){
        this.$store.dispatch('contactus/createContactUs', this.tempContactUs)
          .then(res => {
            console.log(res);
            alert('謝謝您的訊息，我們將盡快與您聯繫！');
            this.tempContactUs = {
              contactus_name: '',
              contactus_phone: '',
              contactus_email: '',
              contactus_content: '',
              captcha_key: '',
              captcha_content: ''
            };
          })
          .catch(err => {
            console.error(err);
            if(err.status == 401){
              alert('驗證碼錯誤');
              this.getCaptcha();
            }
          })
      }
    }
  },
  computed: {
    captcha() {
      return this.$store.getters['auth/captcha'];
    }
  },
  mounted() {
    this.getCaptcha();
    document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
  }
}
</script>
<style lang="scss" scoped>
  .form{
    &-btn{
      cursor: pointer;
      padding: 10px 20px;
      width: 150px;
      text-align: center;
      border: solid 1px #666;
      color: #eee;
      transition: all .2s;
      &:hover{
        background-color: rgba(255, 255, 255, .8);
        color: #666;
        font-weight: 700;
        border: solid 1px #fff;
      }
      &:active{
        background-color: #fff;
        color: #666;
        font-weight: 700;
        border: solid 1px #fff;
      }
    }
    &-label{
      width: 150px;
      color: #ccc;
      &.required{
        &::after{
          content: "*";
          color: red;
        }
      }
    }
    &-input{
      letter-spacing: 2px;
      flex: auto;
      width: 100%;
      height: 35px;
      border-radius: 0px;
      box-shadow: initial;
      background: transparent;
      border: 1px solid #666;
      color: #eee;
      outline: none;
      transition: border .5s;
      padding-left: 10px;
      padding-right: 10px; 
      &:hover, &:focus{
        border: 1px solid #fff;
      }
      &.danger{
        border: solid 1px rgb(124, 0, 0);
      }
    }
    &-captcha{
      cursor: pointer;
      transition: opacity .2s;
      &:hover{
        opacity: .9;
      }
      &:active{
        opacity: .5;
      }
    }
  }
  .social-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    text-decoration: none;
    &:not(:last-child){
      margin-right: 30px;
    }
    img{
      width: 100%;
    }
  }
</style>
