<template>
    <div class="slide">
        <div class="slide__msg flex f-col jcc aic">
            <h4>WEWHISKYBARN</h4>
            <h3>產品介紹</h3>
            <div class="btn btn-square" @click="goToProductList">立即進入</div>
        </div>
        <div class="slide__paginator">
            <div
                @click="changePage(index)"
                :key="page"
                v-for="(page, index) in promote.data.length"
                :class="{ slide__paginator__item: true, active: index == currentIndex}"></div>
        </div>
        <!-- mob -->
        <div class="slide__content show-in-mobile">
            <img
                :key="p.promote_id"
                v-for="(p, index) in promote.data"
                :src="p.promote_img_s"
                :class="{ slide__img: true, active: index == currentIndex }"
                alt="">
                <!-- @click="goToLink(p.promote_link)" -->
        </div>
        <!-- web -->
        <div class="slide__content hide-in-mobile">
            <img
                :key="p.promote_id"
                v-for="(p, index) in promote.data"
                :src="p.promote_img_l"
                :class="{ slide__img: true, active: index == currentIndex }"
                alt="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'Slide',
    props: {
        promote: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            currentIndex: 0,
            sliderTimer: null
        }
    },
    methods: {
        changePage(page) {
            this.currentIndex = page;
        },
        goToProductList() {
            this.$router.push('product-list');
        },
        goToLink(link) {
            window.open(link, '_blank');
        }
    },
    destroyed() {
        clearInterval(this.sliderTimer);
        console.log('clearInterval');
    },
    mounted() {
        this.currentIndex = 0;
        this.sliderTimer = setInterval(() => {
            if(this.currentIndex < this.promote.data.length -1) {
                this.currentIndex += 1;
            } else {
                this.currentIndex = 0;
            }
        }, 10000)
    }
}
</script>
<style scoped lang="scss">
.slide{
    
    position: relative;
    &__msg {
        z-index: 999;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        h4 {
            font-size: 14px;
        }
        h3 {
            font-size: 48px;
            letter-spacing: 10px;
            font-weight: 500;
            line-height: 2;
        }

    }
    &__paginator{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        z-index: 100;
        &__item{
            position: relative;
            width: 15px;
            height: 15px;
            background-color: #ABABAB;
            color: #333;
            border-radius: 50%;
            opacity: .7;
            transition: ease .2s;
            cursor: pointer;
            &.active{
                background-color: #fff;
                color: #fff;
                opacity: 1;
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
            &:hover{
                opacity: .9;
                color: #fff;
            }
            &:active{
                opacity: 1;
            }
            span{
                position: absolute;
                top: 50%;
                left: calc(50% + 3px);
                transform: translate(-50%, -50%);
            }
        }
    }
    &__content{
        filter: brightness(.5);
        // height: 600px;
        overflow: hidden;
    }
    &__img{
        display: none;
        opacity: 0;
        min-width: 100%;
        height: 100vh;
        &.active{
            display: flex;
            animation: loading 2s;
            animation-fill-mode: forwards;
        }
    }
}
@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>