import request from '@/utils/http';
// import requestFile from '@/utils/http-upload';

/** Create contact us
 *
 * @param {Object} payload - request body
 */
export function apiCreateContactUs(payload) {
  return request({
    url: '/contactus',
    method: 'post',
    data: payload,
  });
}
