<template>
  <div class="main">
    <div class="banner">
      <img class="banner__img" src="@/assets/image/bannner.png" alt="">
    </div>
    <div class="flex f-col jcs aic sec px pt">
      <div class="" style="width: 1000px;max-width:100%">
        <div class="flex f-row jcs aic">
          <div class="sec__title" id="content">會員資訊</div>
          <span class="logout" @click="logout">登出</span>
        </div>
        <div class="flex f-col jcs ais">
          <div class="list w-100">
            <div class="flex jcs aic w-100 mb-2 mob-flex-column">
              <div class="form-label mob-w-100">頭像</div>
              <img class="avatar" :src="cus_info.cus_avatar" alt="">
            </div>
            <div class="flex jcs aic w-100 mb-2 mob-flex-column">
              <div class="form-label mob-w-100">姓</div>
              <span class="form-label mob-w-100">{{ cus_info.cus_lname }}</span>
            </div>
            <div class="flex jcs aic w-100 mb-2 mob-flex-column">
              <div class="form-label mob-w-100">名</div>
              <span class="form-label mob-w-100">{{ cus_info.cus_fname }}</span>
            </div>
            <div class="flex jcs aic w-100 mb-2 mob-flex-column">
              <div class="form-label mob-w-100">電子郵件</div>
              <span class="form-label mob-w-100">{{ cus_info.cus_email }}</span>
            </div>
          </div>
        </div>
        <div class="w-100">
          <div class="sec__title" id="content">願望清單</div>
            <div class="flex f-col jcs ais">
              <div class="list w-100">
                <div class="list__header">
                  <div class="list__header__col" style="width: 60%;padding-right: 20px;">
                    <span>名稱</span>
                  </div>
                  <div class="list__header__col" style="width: 20%">
                    <span>庫存</span>
                  </div>
                  <div class="list__header__col" style="width: 20%">
                  </div>
                </div>
                <div v-for="w in wishlist.data" :key="`w-${w.wishlist_id}`" class="list__item" :class="{ 'disabled': w.prod_status == 0 }" @click="goToProductPage(w.prod_id, w.prod_status)">
                  <div class="list__item__col" style="width: 60%;padding-right: 20px;">
                    <span v-if="w.prod_status == 0">(已下架)</span>
                    <span>{{ w.prod_name.split(',')[1] }}</span>
                  </div>
                  <div class="list__item__col" style="width: 20%">
                    <div v-if="w.prod_status == 1">
                      <label v-if="w.prod_stock < 1" class="list__item__col form-label danger" for="">已無庫存</label>
                    <input v-else :value="w.prod_stock" @click.stop="" class="list__item__col form-input" type="number" disabled>
                    </div>
                  </div>
                  <div class="list__item__col" style="width: 20%;text-align: right">
                    <a @click.stop="deleteWishlist(w.wishlist_id)" class="delete">刪除</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="flex jcc aic w-100">
        <a class="social-icon" href="https://www.facebook.com/we.whisky.barn/" target="_blank">
            <img src="@/assets/image/icon/facebook.png" alt="">
        </a>
        <a class="social-icon" href="https://www.instagram.com/we.whisky.barn/" target="_blank">
            <img src="@/assets/image/icon/instagram.png" alt="">
        </a>
        <a class="social-icon" href="tel:+886-925918530">
            <img src="@/assets/image/icon/phone.png" alt="">
        </a>
        <div class="line-it-button" data-lang="zh_Hant" data-type="friend" data-env="REAL"   data-lineId="@989idazb" style="display: none;"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Account',
  components: {
  },
  data(){
    return{
      tempContactUs: {
        contactus_name: '',
        contactus_phone: '',
        contactus_email: '',
        contactus_content: '',
        captcha_key: '',
        captcha_content: ''
      },
      formError: {
        contactus_name: false,
        contactus_phone: false,
        contactus_email: false,
        contactus_content: false,
        captcha_content: false
      }
    }
  },
  methods:{
    async logout() {
      await this.$store.dispatch('auth/logout', this.token);
      this.$router.go('/login');
    },
    goToProductPage(prod_id, prod_status) {
      if(prod_status == 1) {
        this.$router.push({ path: '/product', query: { prod_id } });
      } else {
        alert('酒品已下架!');
      }
      
    },
    async deleteWishlist(wishlist_id) {
      try {
        await this.$store.dispatch('wishlist/deleteWishlist', { token: this.token, wishlist_id });
        alert('刪除成功!');
        await this.getWishlist();
      } catch(error) {
        alert(error);
      }
    },
    async getWishlist() {
      await this.$store.dispatch('wishlist/getWishlist', { token: this.token });
    },
  },
  computed: {
    wishlist() {
      return this.$store.getters['wishlist/wishlist'];
    },
    token() {
      const token = localStorage.getItem('ww_token');
      return token;
    },
    cus_info() {
      const cus_info = localStorage.getItem('ww_info');
      return JSON.parse(cus_info);
    }
  },
  mounted() {
    this.getWishlist();
    document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
  }
}
</script>
<style lang="scss" scoped>
  .form{
    &-btn{
      cursor: pointer;
      padding: 10px 20px;
      width: 150px;
      text-align: center;
      border: solid 1px #666;
      color: #eee;
      transition: all .2s;
      &:hover{
        background-color: rgba(255, 255, 255, .8);
        color: #666;
        font-weight: 700;
        border: solid 1px #fff;
      }
      &:active{
        background-color: #fff;
        color: #666;
        font-weight: 700;
        border: solid 1px #fff;
      }
    }
    &-label{
      width: 150px;
      color: #ccc;
      &.required{
        &::after{
          content: "*";
          color: red;
        }
      }
      &.danger {
        color: red;
      }
    }
    &-input{
      letter-spacing: 2px;
      flex: auto;
      width: 100%;
      height: 35px;
      border-radius: 0px;
      box-shadow: initial;
      background: transparent;
      border: 1px solid #666;
      color: #eee;
      outline: none;
      transition: border .5s;
      padding-left: 10px;
      padding-right: 10px; 
      &:hover, &:focus{
        border: 1px solid #fff;
      }
      &.danger{
        border: solid 1px rgb(124, 0, 0);
      }
    }
    &-captcha{
      cursor: pointer;
      transition: opacity .2s;
      &:hover{
        opacity: .9;
      }
      &:active{
        opacity: .5;
      }
    }
  }
  .social-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    text-decoration: none;
    &:not(:last-child){
      margin-right: 30px;
    }
    img{
      width: 100%;
    }
  }
  .avatar {
    width: 100px;
    border-radius: 50%;
    border: 1px solid #666;
    transition: border .5s;
    &:hover {
      border: 1px solid #fff;
    } 
  }
  .logout{
    cursor: pointer;
    position: relative;
    margin-left: 20px;
    color: #ccc;
    transition: .5s;
    text-align: center;
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -2px;
      left: 0;
      background-color: #ccc;
    }
    &:hover {
      color: #fff;
    }
  }
  @media (max-width: 900px) { 
    .form-label {
      text-align: center;
    }
  }
</style>
