import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import ProductList from '../views/ProductList.vue';
import Product from '../views/Product.vue';
import About from '../views/About.vue';
import ContactUs from '../views/ContactUs.vue';
import Inquire from '../views/Inquire.vue';
import Login from '../views/Login.vue';
import Account from '../views/Account.vue';
const { isAuth } = require('../utils/isAuth');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  // {
  //   path: '/news',
  //   name: 'News',
  //   component: News
  // },
  // {
  //   path: '/new-product',
  //   name: 'NewProduct',
  //   component: NewProduct
  // },
  // {
  //   path: '/map',
  //   name: 'Map',
  //   component: Maps
  // },
  {
    path: '/product-list',
    name: 'ProductList',
    component: ProductList,
    props: (route) => ({
      area_id: route.query.area_id,
      brand_id: route.query.brand_id,
      category_id: route.query.category_id,
    }),
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    props: (route) => ({
      prod_id: route.query.prod_id,
    }),
  },
  // {
  //   path: '/inquiry',
  //   name: 'Inquiry',
  //   component: Inquiry
  // },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/inquire',
    name: 'Inquire',
    component: Inquire,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/account/:token',
    name: 'Account',
    component: Account,
    beforeEnter: async (to, from, next) => {
      try {
        const { data } = await isAuth(to.params.token);
        await localStorage.setItem('ww_token', to.params.token);
        await localStorage.setItem('ww_info', JSON.stringify(data.user));
        next();
      } catch (error) {
        localStorage.clear();
        next('/login');
      }
    },
  },
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   component: Blog
  // }
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.isAuth) {
//     const hq_permission = checkUserPermissions();
//     if (hq_permission.indexOf(to.meta.roleName) > -1) {
//       next();
//     } else {
//       alert('無權限進入該頁面，請重新登入！');
//       router.push('/login');
//     }
//   } else {
//     next();
//   }
// });

export default router;
