import request from '@/utils/http';
// import requestFile from '@/utils/http-upload';

/** Get the wishlist
 *
 * @param {Object} payload - request body
 */
export function apiGetWishlist(payload) {
  return request({
    url: '/wishlist',
    method: 'get',
    headers: { Authorization: payload.token },
  });
}

/** Create the wishlist
 *
 * @param {Object} payload - request body
 */
export function apiCreateWishlist(payload) {
  return request({
    url: `/wishlist`,
    method: 'post',
    data: {
      prod_id: payload.prod_id,
    },
    headers: { Authorization: payload.token },
  });
}

/** Delete the wishlist
 *
 * @param {Object} payload - request body
 */
export function apiDeleteWishlist(payload) {
  return request({
    url: `/wishlist`,
    method: 'delete',
    data: {
      wishlist_id: payload.wishlist_id,
    },
    headers: { Authorization: payload.token },
  });
}
