<template>
  <div class="main">
    <div class="banner">
      <img class="banner__img" src="@/assets/image/bannner.png" alt="">
    </div>
    <div class="flex f-col jcs ais sec px pt">
      <div class="sec__title" id="content">關於我們</div>
      <div class="sec__para">
        歡迎來到威酒穀倉。
      </div>
      <div class="sec__para">
        酒，在世界各地的文化中，不同的人種中，是一種共同的語言。調劑著每個人的生活。
      </div>
      <div class="sec__para">
        您可曾聽過，
        ”對酒當歌，人生幾何”，“古來聖賢皆寂寞，惟有飲者留其名”，”勸君更盡一杯酒…”，”酒意詩情誰與共…”
        從酒出現的場合，可以了解酒在人生中的不同環境和場合有其獨特的義意。
      </div>
      <div class="sec__para">
        威酒穀倉是一群對酒有著熱情的人催生下的品牌。
        基於對客戶的深入了解。誠信，品質和服務是我們的信條。
      </div>
      <div class="sec__para">
        我們致力於提供客戶多樣且不同的威士忌，讓每個客戶體驗到不同的驚喜。
        誠摯的邀請您一起享受威士忌的樂趣…
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {
  },
  data(){
    return{
    }
  },
  methods:{
  },
  computed: {
  },
  mounted() {
    document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
  }
}
</script>
