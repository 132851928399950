<template>
  <div class="main">
    <div class="banner">
      <img class="banner__img" src="@/assets/image/bannner.png" alt="">
    </div>
    <div class="flex f-col jcs px" style="padding-top: 40px" v-if="Object.keys(productList).length > 0">
      <div id="content" class="title mb-3">威士忌品項</div>
      <div class="flex f-row jcs gap-6 f-wrap mob-flex-column w-100 aic" v-if="productList.data.length > 0">
        <div class="product-item" :key="p.prod_id" v-for="p in productList.data" @click="goToProductPage(p)">
          <div class="product-item__badge">
            <div class="product-item__badge__stock">
              <img src="@/assets/image/icon/bottle-yellow.png" alt="">
              <span class="num" v-if="p.prod_stock > 10">{{ p.prod_stock | stockShow }}</span>
              <span class="num" v-else>{{ p.prod_stock }}</span>
              <span class="left">Left</span>
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_WWA > 0">
              <img src="@/assets/image/icon/award/WWA.png" alt="">
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_WB > 0">
              <img src="@/assets/image/icon/award/WB.png" alt="">
              <span>{{ p.prod_WB }}</span>
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_TWBC > 0">
              <img src="@/assets/image/icon/award/TWBC.png" alt="">
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_SWSC > 0">
              <img src="@/assets/image/icon/award/SWSC.png" alt="">
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_MMS > 0">
              <img src="@/assets/image/icon/award/WB.png" alt="">
              <span>{{ p.prod_MMS }}</span>
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_IWSC > 0">
              <img src="@/assets/image/icon/award/IWSC.png" alt="">
            </div>
            <div
              class="product-item__badge__award"
              :class="p.prod_WFS > 0 ? 'sticker2' : 'sticker3'"
              v-if="p.prod_ISC > 0">
              <img src="@/assets/image/icon/award/ISC.png" alt="">
            </div>
            <div class="product-item__badge__fun" v-if="p.prod_WFS > 0">
              <img src="@/assets/image/icon/award/WFS.png" alt="">
              <span>{{ p.prod_WFS }}</span>
            </div>
            <div class="product-item__badge__tails">
              <div class="left"></div>
              <div class="right"></div>
            </div>
          </div>
          <!-- <div class="product-item__bg"></div> -->
          <div class="product-item__img" v-if="p.prod_img !== null && p.prod_img !== ''">
            <img :src="p.prod_img[0]" alt="">
          </div>
          <div class="product-item__info flex f-col">
              <div class="hr"></div>
              <span class="product-item__name">{{ p.prod_name[1] | prodNameShow }}</span>
              <div class="flex f-col w-100">
                <div class="flex f-row jcs aic mb-1">
                  <img class="product-item__star" src="@/assets/image/icon/star.png" alt="">
                  <img class="product-item__star" src="@/assets/image/icon/star.png" alt="">
                  <img class="product-item__star" src="@/assets/image/icon/star.png" alt="">
                  <img class="product-item__star" src="@/assets/image/icon/star.png" alt="">
                  <img class="product-item__star" src="@/assets/image/icon/star1.png" alt="">
                </div>
                <div class="product-item__text mb-1">建議售價: NT<span class="product-item__price">{{ p.prod_suggest_price | numberWithCommas }}</span></div>
                <div class="flex f-row jcsb aic w-100">
                  <div class=""></div>
                  <!-- <div class="product-item__text flex f-row aic">放入詢問箱<img class="icon" src="@/assets/image/icon/shopping-bag-black.png" alt=""></div> -->
                  <div class="product-item__text flex f-row aic">Add to Wishlist<img class="icon" src="@/assets/image/icon/heart-black.png" alt=""></div>
                </div>
                
              </div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        無資料.
      </div>
      <Paginator
        v-if="productList.pagination"
        :paginator="productList.pagination"
        @changePage="changePage"></Paginator>
      <Map></Map>
    </div>
  </div>
</template>

<script>
import Map from '@/components/Map.vue'
import Paginator from '@/components/Paginator.vue'
export default {
  name: 'ProductList',
  components: {
    Map,
    Paginator,
  },
  props: {
    area_id: {
      type: String,
      default: null,
    },
    brand_id: {
      type: String,
      default: null,
    },
    category_id: {
      type: String,
      default: null,
    }
  },
  watch: {
    category_id() {
      this.currentPage = 1;
      this.getProductList();
    },
    brand_id() {
      this.currentPage = 1;
      this.getProductList();
    },
    area_id() {
      this.currentPage = 1;
      this.getProductList();
    }
  },
  data(){
    return{
      areaList: ['lowlands', 'highlands', 'speyside', 'islay', 'islands', 'campbeltown'],
      currentPage: 1
    }
  },
  filters: {
    prodNameShow(str) {
      const strlength = str.replace('/[^\x00-\xff]/g', str).length;
      console.log(strlength);
      if (strlength > 15) {
        return `${str.substring(0, 15)}...`;
      } else {
        return str;
      }
    },
    numberWithCommas(price) {
      if (price !== null && price !== undefined) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return price;
      }
    },
    stockShow(stock = 0) {
      if (stock > 0 && stock > 10) {
        return '10';
      } else {
        return stock;
      }
    },
  },
  methods:{
    replaceErrorImg(e) {
      e.target.src = '';
    },
    changePage(page) {
      this.currentPage = page;
      this.getProductList();
    },
    async getProductList() {
      const params = { 
        currentPage: this.currentPage, 
        perPage: 20, 
        cate_id: this.cate_id,
        brand_id: this.brand_id,  
        area_id: this.area_id,
        category_id: this.category_id
      };
      await this.$store.dispatch('product/getProductList', params);
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
    async goToProductPage(product){
      // await this.$store.dispatch('product/setTempProduct', { product_id: product.product_id });
      await this.$router.push({ path: '/product', query: { prod_id: product.prod_id } });
    },
  },
  computed: {
    productList() {
      return this.$store.getters['product/productList'];
    },
  },
  mounted() {
    this.getProductList();
  }
}
</script>
