import request from '@/utils/http';
// import requestFile from '@/utils/http-upload';

/** Get the promote list
 *
 * @param {Object} payload - request body
 */
export function apiGetPrometeList(payload) {
  return request({
    url: '/promote',
    method: 'get',
    params: payload,
  });
}
