<template>
  <div id="app">
    <Loading :isLoading="isLoading"></Loading>
    <Header
      :areaList="areaList.data"
      :brandList="brandList.data"
    ></Header>
    <router-view/>
    <Footer></Footer>
    <!-- <Messenger></Messenger> -->
    <!-- <LineLink></LineLink> -->

  </div>
</template>
<script src="https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js" async="async" defer="defer"></script>

<script>
// @ is an alias to /src
// import Messenger from '@/components/Messenger.vue'
// import LineLink from '@/components/LineLink.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loading,
    // LineLink,
  },
  methods: {
    async init() {
      await this.$store.dispatch('product/getAreaList');
      await this.$store.dispatch('product/getBrandList');
    },
  },
  watch: {
    isMobMenuOpen() {
      document.body.style.overflow = this.isMobMenuOpen ? 'hidden' : ''
    }
  },
  computed: {
    areaList() {
      return this.$store.getters['product/areaList'];
    },
    brandList() {
      return this.$store.getters['product/brandList'];
    },
    isLoading() {
      return this.$store.getters['isLoading'];
    },
    isMobMenuOpen() {
      return this.$store.getters['isMobMenuOpen'];
    }
  },
  mounted() {
    this.init();
  }
}
</script>
