const { apiVerifyToken } = require('../api/auth');
const isAuth = (token) => {
  return new Promise((resolve, reject) => {
    apiVerifyToken(token)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

module.exports = { isAuth };
