import request from '@/utils/http';
// import requestFile from '@/utils/http-upload';

/** Get the product list
 *
 * @param {Object} payload - request body
 */
export function apiGetProductList(payload) {
  return request({
    url: '/product',
    method: 'get',
    params: payload,
  });
}

/** Get the product by prod_id
 *
 * @param {Object} payload - request body
 */
export function apiGetProductById(payload) {
  return request({
    url: `/product/${payload}`,
    method: 'get',
  });
}

/** Get the area list
 *
 * @param {Object} payload - request body
 */
export function apiGetAreaList() {
  return request({
    url: '/area',
    method: 'get',
  });
}

/** Get the brand list
 *
 * @param {Object} payload - request body
 */
export function apiGetBrandList() {
  return request({
    url: '/brand',
    method: 'get',
  });
}
