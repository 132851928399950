import { apiGetCaptcha, apiGoogleLogin, apiLogout } from '@/api/auth';

export default {
  namespaced: true,
  state: {
    captcha: {},
    account: {},
  },
  getters: {
    captcha: (state) => state.captcha,
    account: (state) => state.account,
  },
  mutations: {
    ACCOUNT(state, payload) {
      state.account = payload;
    },
    SET_CAPTCHA(state, captcha) {
      state.captcha = captcha;
    },
  },
  actions: {
    googleLogin(state) {
      return new Promise((resolve, reject) => {
        apiGoogleLogin()
          .then((res) => {
            console.log(res.data);
            state.commit('ACCOUNT', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    getCaptcha(state, params) {
      return new Promise((resolve, reject) => {
        apiGetCaptcha(params)
          .then((res) => {
            state.commit('SET_CAPTCHA', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    logout(state, params) {
      return new Promise((resolve, reject) => {
        apiLogout(params)
          .then(() => {
            state.commit('ACCOUNT', {});
            localStorage.clear();
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
  },
};
