import axios from 'axios';

const apiurl = process.env.VUE_APP_API_BASE_URL;

const service = axios.create({
  baseURL: apiurl,
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorCode;
    if (error && error.response && (typeof error.response.status !== 'undefined')) errorCode = error.response.status;

    console.log(errorCode);
    console.log(error);

    return Promise.reject(error);
  },
);

export default service;
