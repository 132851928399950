<template>
    <div class="paginator w-100 flex jcc aic">
        <div
            @click="changePage(paginator.currentPage - 1)"
            class="paginator__item prev"
            v-if="paginator.currentPage > 1"></div>
        <div
            :key="index"
            v-for="(p, index) in paginator.totalPage"
            :class="{ paginator__item: true, active: index + 1 == paginator.currentPage }"
            @click="changePage(index+1)">
            <span>{{ index + 1 }}</span>
        </div>
        <div
            @click="changePage(paginator.currentPage + 1)"
            class="paginator__item next"
            v-if="paginator.currentPage < paginator.totalPage"></div>
    </div>
</template>
<script>
export default {
    name: 'Paginator',
    props: {
        paginator: {
            type: Object,
            require: true
        }
    },
    data() {
        return {

        }
    },
    methods: {
        changePage(page) {
            this.$emit('changePage', page);
        }
    }
}
</script>
<style scoped lang="scss">
    .paginator{
        margin-top: 60px;
        margin-bottom: 20px;
        &__item{
            position: relative;
            width: 30px;
            height: 30px;
            background-color: #ABABAB;
            color: #333;
            border-radius: 50%;
            opacity: .7;
            transition: ease .2s;
            cursor: pointer;
            &.prev::before{
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                background-color: #fff;
                top: 51%;
                left: 50%;
                transform-origin: 0%;
                transform: translateX(-50%) rotate(-45deg);
            }
            &.prev::after{
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                background-color: #fff;
                top: 49%;
                left: 50%;
                transform-origin: 0%;
                transform: translateX(-50%) rotate(45deg);
            }
            &.next::before{
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                background-color: #fff;
                top: 51%;
                left: 50%;
                transform-origin: 100%;
                transform: translateX(-50%) rotate(45deg);
            }
            &.next::after{
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                background-color: #fff;
                top: 49%;
                left: 50%;
                transform-origin: 100%;
                transform: translateX(-50%) rotate(-45deg);
            }
            &.active{
                color: #fff;
                opacity: 1;
            }
            &:not(:last-child){
                margin-right: 10px;
            }
            &:hover{
                opacity: .9;
                color: #fff;
            }
            &:active{
                opacity: 1;
            }
            span{
                position: absolute;
                top: 50%;
                left: calc(50% + 3px);
                transform: translate(-50%, -50%);
            }
        }
    }
</style>