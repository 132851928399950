import request from '@/utils/http';

/** Get the captcha data
 *
 * @param {Object} payload - request body
 */
export function apiGetCaptcha(payload) {
  return request({
    url: '/auth/captcha',
    method: 'get',
    params: payload,
  });
}

/** Verify token
 *
 * @param {Object} payload - request body
 */
export function apiVerifyToken(payload) {
  return request({
    url: '/auth/token',
    method: 'get',
    headers: { Authorization: payload },
  });
}

/** Logout
 *
 * @param {Object} payload - request body
 */
export function apiLogout(payload) {
  return request({
    url: '/auth/logout',
    method: 'post',
    headers: { Authorization: payload },
  });
}
