import Vue from 'vue';
import Vuex from 'vuex';
import product from './modules/product';
import promote from './modules/promote';
import auth from './modules/auth';
import contactus from './modules/contactus';
import wishlist from './modules/wishlist';
import review from './modules/review';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    isMobMenuOpen: false,
  },
  mutations: {
    SET_ISLOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_ISMOBMENUOPEN(state, isMobMenuOpen) {
      state.isMobMenuOpen = isMobMenuOpen;
    },
  },
  actions: {
    async setIsLoading(state) {
      await state.commit('SET_ISLOADING', true);
      await setTimeout(() => {
        state.commit('SET_ISLOADING', false);
      }, 2000);
    },
    async setIsMobMenuOpen(state, isMobMenuOpen) {
      await state.commit('SET_ISMOBMENUOPEN', isMobMenuOpen);
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isMobMenuOpen: (state) => state.isMobMenuOpen,
  },
  modules: {
    product,
    promote,
    auth,
    contactus,
    wishlist,
    review,
  },
});
