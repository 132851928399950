import {
  apiGetProductList,
  apiGetProductById,
  apiGetAreaList,
  apiGetBrandList,
} from '@/api/product';
// import checkIsAuthError from '@/utils/check-is-auth-error';

export default {
  namespaced: true,
  state: {
    product: {},
    product_list: {},
    areaList: [],
    brandList: [],
    selectedAreaId: -1,
    isAreaListError: false,
  },
  getters: {
    productList: (state) => state.product_list,
    product: (state) => state.product,
    selectedEmployeeID: (state) => state.selectedEmployeeID,
    areaList: (state) => state.areaList,
    brandList: (state) => state.brandList,
    selectedAreaId: (state) => state.selectedAreaId,
    isAreaListError: (state) => state.isAreaListError,
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_AREALIST(state, list) {
      state.areaList = list;
    },
    SET_BRANDLIST(state, list) {
      state.brandList = list;
    },
    SET_IS_AREA_LIST_ERROR(state, bool) {
      state.isAreaListError = bool;
    },
    SET_PRODUCTLIST(state, payload) {
      state.product_list = payload;
    },
  },
  actions: {
    getProductList(state, params) {
      return new Promise((resolve, reject) => {
        state.dispatch('setIsLoading', null, { root: true });
        apiGetProductList(params)
          .then((res) => {
            state.commit('SET_PRODUCTLIST', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    getProductById(state, prod_id) {
      state.dispatch('setIsLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        apiGetProductById(prod_id)
          .then((res) => {
            state.commit('SET_PRODUCT', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    getAreaList(state) {
      return new Promise((resolve, reject) => {
        apiGetAreaList()
          .then((res) => {
            state.commit('SET_AREALIST', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    getBrandList(state, params) {
      return new Promise((resolve, reject) => {
        apiGetBrandList(params)
          .then((res) => {
            state.commit('SET_BRANDLIST', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
    setSelectedAreaId(state, id) {
      state.commit('SET_SELECTED_AREA_ID', id);
    },
  },
};
