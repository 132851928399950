import { apiCreateContactUs } from '@/api/contactus';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    createContactUs(state, payload) {
      return new Promise((resolve, reject) => {
        apiCreateContactUs(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
  },
};
