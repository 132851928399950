<template>
  <div class="w-100">
    <div class="map px flex f-row jcs ais hide-in-mobile" :class="areaList[area_id-1]" style="height: 1000px">
      <div class="flex f-row jcc aic por" id="content">
        <img class="map__bg" src="@/assets/image/map/glass.png" alt="">
        <img class="map__bottom" src="@/assets/image/map/map--plain.png">
        <img v-if="area_id == 3" class="map__item speyside" src="@/assets/image/map/speyside.png">
        <img v-if="area_id == 2" class="map__item highlands" src="@/assets/image/map/highlands.png">
        <img v-if="area_id == 1" class="map__item lowlands" src="@/assets/image/map/lowlands.png">
        <img v-if="area_id == 5" class="map__item islands" src="@/assets/image/map/islands.png">
        <img v-if="area_id == 4" class="map__item islay" src="@/assets/image/map/islay.png">
        <img v-if="area_id == 6" class="map__item campbeltown" src="@/assets/image/map/campbeltown.png">
      </div>
      <div class="flex f-col jcs ais" style="margin-top: 200px">
        <div class="flex f-col jcs ais">
          <div class="map__label speyside"  @mouseover="area_id = 3" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">斯貝塞區（Speyside）</span>
          </div>
          <div class="map__label highlands" @mouseover="area_id = 2" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">高地區（Highlands）</span>
          </div>
          <div class="map__label lowlands" @mouseover="area_id = 1" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">低地區（Lowlands）</span>
          </div>
          <div class="map__label islands" @mouseover="area_id = 5" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">島嶼區（Islands）</span>
          </div>
          <div class="map__label islay" @mouseover="area_id = 4" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">艾雷島（Islay）</span>
          </div>
          <div class="map__label campbeltown" @mouseover="area_id = 6" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">坎貝爾鎮（Campbeltown）</span>
          </div>
        </div>
        <div class="map__label__desc speyside" v-if="area_id == 3">
          斯貝塞區（Speyside）
          <br><br>
          斯貝塞區其實是位在高地區內，但因區內酒廠密度太高，所以獨立為一區。本區有著名的斯貝河（Spey River）流過，水量充沛、水質優良，因此有眾多酒廠聚集在此。
          <br><br>
          本區釀出來的酒因口感柔順，成為蘇格蘭威士忌最受關注的產地。區內的達夫鎮（Dufftown）也成為威士忌愛好者造訪蘇格蘭的首選之地。在這裡的酒廠有單一純麥銷售量第一的格蘭菲迪、好喝的百富、大名頂頂的格蘭利威、雪莉風格的格蘭花格與格蘭多納、投資界的寶石麥卡倫、優雅的卡杜、2.81次蒸餾的慕赫…等等。還有很多值得大家一一探索的酒廠，都是斯貝塞區每個酒友不可錯過的。
        </div>
        <div class="map__label__desc highlands" v-if="area_id == 2">
          高地區（Highlands）
          <br><br>
          高地區為範圍最大、酒廠最多的一區。以地理位置來說艾雷島、島嶼區與斯貝賽區都在其中，但對酒的產區而言，因各有其地理特殊性所以分開來論。
          高地區與低地區以所謂的高地線區隔。當初這條線畫分稅率的不同，導致一堆酒廠往高地山裡躲，也讓低地區沒落。而高地區也因為範圍大，酒廠多，所以風格上各家有其異趣，不同的山川起伏、變化多端的天氣、地質、雨水、還有人文，都是影響威士忌風味的因素，需要大家一起來探索。
        </div>
        <div class="map__label__desc lowlands" v-if="area_id == 1">
          低地區(Lowlands)
          <br><br>
          低地區之所以稱為低地，因其地勢低，平坦。相傳愛爾蘭的釀造技術就是由低地區傳入，這裡有著名的城市愛丁堡跟格拉斯哥，充滿人文氣息。老牌酒廠有Ailsa Bay、格蘭金奇（Glenkinchie）、歐肯特軒（Auchentoshan）和Bladnoch。還有1993關廠的知名酒廠RoseBank，目前拍賣還可以看到，以及另一家1994關廠，可能為蘇格蘭最老酒廠的Littlemill。近年來，新酒廠也紛紛加入低地區的行列，如 2005年12月的Daftmill，然後是2012年的Eden Mill St Andrews，能釀啤酒與威士忌的微型精釀酒廠，還有2014年開始營運的Kingsbarns酒廠和Annandale酒廠、2016年的InchDairnie酒廠、2017年的Clydeside酒廠和Lindores Abbey及2020年的Ardgowan酒廠。
        </div>
        <div class="map__label__desc islands" v-if="area_id == 5">
          島嶼區（Islands）
          <br><br>
          蘇格蘭北方高地區周邊有許多破碎地形形成的島嶼。而目前有酒廠的島嶼分別是奧克尼島，哈理斯島，天空島，拉瑟島，茂爾島，吉拉島與愛倫島。
          <br><br>
          奧克尼島（Orkney），蘇格蘭最北端的島嶼，當然就會有蘇格蘭最北端的酒廠，高原騎士（Highland Park）。另一家斯卡帕（Scapa），兩家酒廠風格完全不同，酒友們可多加比較。
          <br>
          天空島（Skye），知名的泰斯卡（Talisker）本是島上唯一酒廠。2017年又開了一家Torabhaig的新酒廠。
          <br>
          拉瑟島（Raasay），島上有拉瑟酒廠。
          <br>
          茂爾島（Mull），大家熟知的托本莫瑞酒廠（Tobermory），其有兩個品牌，無泥煤為托本莫瑞，有泥煤威士忌稱為里爵（Ledaig）。
          <br>
          吉拉島（Jura），就在艾雷島隔壁，從布納哈本酒廠可看到島上的山坡，非常驚豔的地方。上面有吉拉酒廠，非常值得一試。
          <br>
          愛倫島（Arran），在低地與坎貝鎮之間的島嶼，愛倫酒廠是島上唯一酒廠。
        </div>
        <div class="map__label__desc islay" v-if="area_id == 4">
          艾雷島（Islay）
          <br><br>
          艾雷島的風格以泥煤風格而為大眾熟知，是所有酒友一生必去一次的威士忌的聖地，島上的酒廠，波摩（Bowmore）、雅柏（Ardbeg）、拉弗格（Laphroaig）、布納哈本（Bunnahabhain）、樂加維林（Lagavulin）、卡爾里拉（Coal ila）、布萊迪（Bruichladdich），以及齊侯門（Kichoman）都是大家熟知的酒廠。
        </div>
        <div class="map__label__desc campbeltown" v-if="area_id == 6">
          坎貝爾鎮（Campbeltown）
          <br><br>
          一度要取消的產區，扛把子就是雲頂，雲頂堅持傳統純手工釀造，本身就是一個特色，而且產量少，每年出的酒款都是款款秒殺。
          <br>
          坎貝爾鎮位於琴泰岬半島、吉拉島與愛倫島中間，靠近愛爾蘭。過去曾有多達28家酒廠，因此被稱為「世界的威士忌首都」。但因1920年代美國禁酒令，加上景氣蕭條，到2010年為止，這裡只剩三家酒廠仍在運作生產，榮景不再。
          <br>
          第一家酒廠為雲頂（Springbank），生產3種不同風格的威士忌：分別為中度泥煤的雲頂、重泥煤的朗格羅（Longrow）和無泥煤的赫佐本（Hazelburn）。
          <br>
          第二家是格蘭蓋爾（Glengyle）酒廠，這是雲頂為了法規：一個產區至少有三家蒸餾廠之故，因此挹注資金重新復廠，該酒廠推出的酒款名稱為齊克倫（Kilkerran）。目前正在酒友中慢慢發燒中。
          <br>
          第三家葛蘭帝（Glen Scotia）酒廠，其奶油焦糖風味且厚實酒體，贏得許多酒友的喜愛。
        </div>
      </div>
    </div>
    <div class="map px flex f-row jcs ais mob-flex-column show-in-mobile" :class="areaList[area_id-1]">
      <div class="flex f-row jcc aic por mob-w-100" id="content">
        <img class="map__bg mob-w-100" src="@/assets/image/map/glass.png" alt="">
        <img class="map__bottom mob-w-100" src="@/assets/image/map/map--plain.png">
        <!-- <img v-if="area_id == 3" class="map__item speyside" src="@/assets/image/map/speyside.png">
        <img v-if="area_id == 2" class="map__item highlands" src="@/assets/image/map/highlands.png">
        <img v-if="area_id == 1" class="map__item lowlands" src="@/assets/image/map/lowlands.png">
        <img v-if="area_id == 5" class="map__item islands" src="@/assets/image/map/islands.png">
        <img v-if="area_id == 4" class="map__item islay" src="@/assets/image/map/islay.png">
        <img v-if="area_id == 6" class="map__item campbeltown" src="@/assets/image/map/campbeltown.png"> -->
      </div>
      <div class="flex f-col jcs ais mt-2">
        <div class="flex f-col jcs ais">
          <div class="map__label speyside"  @mouseover="area_id = 3" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">斯貝塞區（Speyside）</span>
          </div>
          <div class="map__label highlands" @mouseover="area_id = 2" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">高地區（Highlands）</span>
          </div>
          <div class="map__label lowlands" @mouseover="area_id = 1" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">低地區（Lowlands）</span>
          </div>
          <div class="map__label islands" @mouseover="area_id = 5" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">島嶼區（Islands）</span>
          </div>
          <div class="map__label islay" @mouseover="area_id = 4" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">艾雷島（Islay）</span>
          </div>
          <div class="map__label campbeltown" @mouseover="area_id = 6" @click="getProductList">
            <div class="map__label__square"></div>
            <span class="map__label__name">坎貝爾鎮（Campbeltown）</span>
          </div>
        </div>
        <div class="map__label__desc speyside" v-if="area_id == 3">
          斯貝塞區（Speyside）
          <br><br>
          斯貝塞區其實是位在高地區內，但因區內酒廠密度太高，所以獨立為一區。本區有著名的斯貝河（Spey River）流過，水量充沛、水質優良，因此有眾多酒廠聚集在此。
          <br><br>
          本區釀出來的酒因口感柔順，成為蘇格蘭威士忌最受關注的產地。區內的達夫鎮（Dufftown）也成為威士忌愛好者造訪蘇格蘭的首選之地。在這裡的酒廠有單一純麥銷售量第一的格蘭菲迪、好喝的百富、大名頂頂的格蘭利威、雪莉風格的格蘭花格與格蘭多納、投資界的寶石麥卡倫、優雅的卡杜、2.81次蒸餾的慕赫…等等。還有很多值得大家一一探索的酒廠，都是斯貝塞區每個酒友不可錯過的。
        </div>
        <div class="map__label__desc highlands" v-if="area_id == 2">
          高地區（Highlands）
          <br><br>
          高地區為範圍最大、酒廠最多的一區。以地理位置來說艾雷島、島嶼區與斯貝賽區都在其中，但對酒的產區而言，因各有其地理特殊性所以分開來論。
          高地區與低地區以所謂的高地線區隔。當初這條線畫分稅率的不同，導致一堆酒廠往高地山裡躲，也讓低地區沒落。而高地區也因為範圍大，酒廠多，所以風格上各家有其異趣，不同的山川起伏、變化多端的天氣、地質、雨水、還有人文，都是影響威士忌風味的因素，需要大家一起來探索。
        </div>
        <div class="map__label__desc lowlands" v-if="area_id == 1">
          低地區(Lowlands)
          <br><br>
          低地區之所以稱為低地，因其地勢低，平坦。相傳愛爾蘭的釀造技術就是由低地區傳入，這裡有著名的城市愛丁堡跟格拉斯哥，充滿人文氣息。老牌酒廠有Ailsa Bay、格蘭金奇（Glenkinchie）、歐肯特軒（Auchentoshan）和Bladnoch。還有1993關廠的知名酒廠RoseBank，目前拍賣還可以看到，以及另一家1994關廠，可能為蘇格蘭最老酒廠的Littlemill。近年來，新酒廠也紛紛加入低地區的行列，如 2005年12月的Daftmill，然後是2012年的Eden Mill St Andrews，能釀啤酒與威士忌的微型精釀酒廠，還有2014年開始營運的Kingsbarns酒廠和Annandale酒廠、2016年的InchDairnie酒廠、2017年的Clydeside酒廠和Lindores Abbey及2020年的Ardgowan酒廠。
        </div>
        <div class="map__label__desc islands" v-if="area_id == 5">
          島嶼區（Islands）
          <br><br>
          蘇格蘭北方高地區周邊有許多破碎地形形成的島嶼。而目前有酒廠的島嶼分別是奧克尼島，哈理斯島，天空島，拉瑟島，茂爾島，吉拉島與愛倫島。
          <br><br>
          奧克尼島（Orkney），蘇格蘭最北端的島嶼，當然就會有蘇格蘭最北端的酒廠，高原騎士（Highland Park）。另一家斯卡帕（Scapa），兩家酒廠風格完全不同，酒友們可多加比較。
          <br>
          天空島（Skye），知名的泰斯卡（Talisker）本是島上唯一酒廠。2017年又開了一家Torabhaig的新酒廠。
          <br>
          拉瑟島（Raasay），島上有拉瑟酒廠。
          <br>
          茂爾島（Mull），大家熟知的托本莫瑞酒廠（Tobermory），其有兩個品牌，無泥煤為托本莫瑞，有泥煤威士忌稱為里爵（Ledaig）。
          <br>
          吉拉島（Jura），就在艾雷島隔壁，從布納哈本酒廠可看到島上的山坡，非常驚豔的地方。上面有吉拉酒廠，非常值得一試。
          <br>
          愛倫島（Arran），在低地與坎貝鎮之間的島嶼，愛倫酒廠是島上唯一酒廠。
        </div>
        <div class="map__label__desc islay" v-if="area_id == 4">
          艾雷島（Islay）
          <br><br>
          艾雷島的風格以泥煤風格而為大眾熟知，是所有酒友一生必去一次的威士忌的聖地，島上的酒廠，波摩（Bowmore）、雅柏（Ardbeg）、拉弗格（Laphroaig）、布納哈本（Bunnahabhain）、樂加維林（Lagavulin）、卡爾里拉（Coal ila）、布萊迪（Bruichladdich），以及齊侯門（Kichoman）都是大家熟知的酒廠。
        </div>
        <div class="map__label__desc campbeltown" v-if="area_id == 6">
          坎貝爾鎮（Campbeltown）
          <br><br>
          一度要取消的產區，扛把子就是雲頂，雲頂堅持傳統純手工釀造，本身就是一個特色，而且產量少，每年出的酒款都是款款秒殺。
          <br>
          坎貝爾鎮位於琴泰岬半島、吉拉島與愛倫島中間，靠近愛爾蘭。過去曾有多達28家酒廠，因此被稱為「世界的威士忌首都」。但因1920年代美國禁酒令，加上景氣蕭條，到2010年為止，這裡只剩三家酒廠仍在運作生產，榮景不再。
          <br>
          第一家酒廠為雲頂（Springbank），生產3種不同風格的威士忌：分別為中度泥煤的雲頂、重泥煤的朗格羅（Longrow）和無泥煤的赫佐本（Hazelburn）。
          <br>
          第二家是格蘭蓋爾（Glengyle）酒廠，這是雲頂為了法規：一個產區至少有三家蒸餾廠之故，因此挹注資金重新復廠，該酒廠推出的酒款名稱為齊克倫（Kilkerran）。目前正在酒友中慢慢發燒中。
          <br>
          第三家葛蘭帝（Glen Scotia）酒廠，其奶油焦糖風味且厚實酒體，贏得許多酒友的喜愛。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map',
  components: {
  },
  data(){
    return{
      areaList: ['lowlands', 'highlands', 'speyside', 'islay', 'islands', 'campbeltown'],
      area_id: 1,
    }
  },
  methods:{
    getProductList() {
      this.$router.push({ path: '/product-list', query: { area_id: this.area_id } });
      document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
    }
  },
  computed: {
  },
}
</script>
