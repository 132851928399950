<template>
  <div class="main">
    <div class="banner">
      <img class="banner__img" src="@/assets/image/bannner.png" alt="">
    </div>
    <div class="flex f-col jcs ais sec px pt">
      <div class="sec__title" id="content">聯絡我們</div>
      <!-- <div class="sec__para">
        歡迎來到威酒穀倉。
      </div> -->
      <div class="w-100 flex f-col jcs ais">
        <div class="flex jcs aic w-100 mb-2">
          <div class="form-label required">姓名</div>
          <input
            @change="checkForm"
            v-model="tempContactUs.contactus_name"
            type="text"
            class="form-input"
            :class="{ danger: formError.contactus_name }">
        </div>
        <div class="flex jcs aic w-100 mb-2">
          <div class="form-label required">聯絡電話</div>
          <input
            @change="checkForm"
            v-model="tempContactUs.contactus_phone"
            type="tel"
            class="form-input"
            :class="{ danger: formError.contactus_phone }">
        </div>
        <div class="flex jcs aic w-100 mb-2">
          <div class="form-label required">電子郵件</div>
          <input
            @change="checkForm"
            v-model="tempContactUs.contactus_email"
            type="text"
            class="form-input"
            :class="{ danger: formError.contactus_email }">
        </div>
        <div class="flex jcs aic w-100 mb-2">
          <div class="form-label">備註</div>
          <input v-model="tempContactUs.contactus_content" type="text" class="form-input">
        </div>
        <div class="flex jcs aic w-100 mb-3">
          <div class="form-label required">驗證碼</div>
          <div class="flex jcs aic w-100">
            <input
              @change="checkForm"
              v-model="tempContactUs.captcha_content"
              type="text"
              class="form-input mr-1"
              :class="{ danger: formError.captcha_content }">
            <div
              @click="changeCaptcha"
              class="form-captcha"
              v-html="captcha.captcha"></div>
          </div>
        </div>
        <div class="flex jce aic w-100">
          <div @click="createContactUs" class="form-btn mob-w-100 mob-mb-3">送出</div>
        </div>
        <div class="flex jcc aic w-100">
          <a class="social-icon" href="https://www.facebook.com/we.whisky.barn/" target="_blank">
              <img src="@/assets/image/icon/facebook.png" alt="">
          </a>
          <a class="social-icon" href="https://www.instagram.com/we.whisky.barn/" target="_blank">
              <img src="@/assets/image/icon/instagram.png" alt="">
          </a>
          <a class="social-icon" href="tel:+886-925918530">
              <img src="@/assets/image/icon/phone.png" alt="">
          </a>
          <div class="line-it-button" data-lang="zh_Hant" data-type="friend" data-env="REAL"   data-lineId="@989idazb" style="display: none;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  components: {
  },
  data(){
    return{
      tempContactUs: {
        contactus_name: '',
        contactus_phone: '',
        contactus_email: '',
        contactus_content: '',
        captcha_key: '',
        captcha_content: ''
      },
      formError: {
        contactus_name: false,
        contactus_phone: false,
        contactus_email: false,
        contactus_content: false,
        captcha_content: false
      }
    }
  },
  methods:{
    async getCaptcha() {
      await this.$store.dispatch('auth/getCaptcha');
      this.tempContactUs.captcha_key = this.captcha.token;
    },
    changeCaptcha() {
      this.getCaptcha();
    },
    checkForm() {
      const emailRule = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      this.formError = {
        contactus_name: false,
        contactus_phone: false,
        contactus_email: false,
        contactus_content: false,
        captcha_content: false
      };
      if (!this.tempContactUs.contactus_name) { this.formError.contactus_name = true }
      if (!this.tempContactUs.contactus_phone) { this.formError.contactus_phone = true }
      if (!this.tempContactUs.contactus_email || !this.tempContactUs.contactus_email.match(emailRule)) { 
        this.formError.contactus_email = true;
      }
      if (!this.tempContactUs.captcha_content) { this.formError.captcha_content = true }
    },
    createContactUs() {
      this.checkForm();
      let errors = Object.values(this.formError);
      errors = errors.filter(item => {
        return item == true;
      })
      if(errors.length < 1){
        this.$store.dispatch('contactus/createContactUs', this.tempContactUs)
          .then(res => {
            console.log(res);
            alert('謝謝您的訊息，我們將盡快與您聯繫！');
            this.tempContactUs = {
              contactus_name: '',
              contactus_phone: '',
              contactus_email: '',
              contactus_content: '',
              captcha_key: '',
              captcha_content: ''
            };
          })
          .catch(err => {
            console.error(err);
            if(err.status == 401){
              alert('驗證碼錯誤');
              this.getCaptcha();
            }
          })
      }
    }
  },
  computed: {
    captcha() {
      return this.$store.getters['auth/captcha'];
    }
  },
  mounted() {
    this.getCaptcha();
    document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
  }
}
</script>
<style lang="scss" scoped>
  .form{
    &-btn{
      cursor: pointer;
      padding: 10px 20px;
      width: 150px;
      text-align: center;
      border: solid 1px #666;
      color: #eee;
      transition: all .2s;
      &:hover{
        background-color: rgba(255, 255, 255, .8);
        color: #666;
        font-weight: 700;
        border: solid 1px #fff;
      }
      &:active{
        background-color: #fff;
        color: #666;
        font-weight: 700;
        border: solid 1px #fff;
      }
    }
    &-label{
      width: 150px;
      color: #ccc;
      &.required{
        &::after{
          content: "*";
          color: red;
        }
      }
    }
    &-input{
      letter-spacing: 2px;
      flex: auto;
      width: 100%;
      height: 35px;
      border-radius: 0px;
      box-shadow: initial;
      background: transparent;
      border: 1px solid #666;
      color: #eee;
      outline: none;
      transition: border .5s;
      padding-left: 10px;
      padding-right: 10px; 
      &:hover, &:focus{
        border: 1px solid #fff;
      }
      &.danger{
        border: solid 1px rgb(124, 0, 0);
      }
    }
    &-captcha{
      cursor: pointer;
      transition: opacity .2s;
      &:hover{
        opacity: .9;
      }
      &:active{
        opacity: .5;
      }
    }
  }
  .social-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    text-decoration: none;
    &:not(:last-child){
      margin-right: 30px;
    }
    img{
      width: 100%;
    }
  }
</style>
