import { apiGetPrometeList } from '@/api/promote';
// import checkIsAuthError from '@/utils/check-is-auth-error';

export default {
  namespaced: true,
  state: {
    promote_list: {},
    selectedAreaId: -1,
    isAreaListError: false,
  },
  getters: {
    promoteList: (state) => state.promote_list,
  },
  mutations: {
    SET_PROMOTELIST(state, payload) {
      state.promote_list = payload;
    } 
  },
  actions: {
    getPromoteList(state, params) {
      return new Promise((resolve, reject) => {
        state.dispatch('setIsLoading', null, { root: true });
        apiGetPrometeList(params)
          .then((res) => {
            state.commit('SET_PROMOTELIST', res.data);
            resolve();
          })
          .catch((err) => {
            console.warn(err);
            reject();
          });
      });
    },
  },
};
